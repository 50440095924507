<template>
    <div class="homapge">
        <div class="title">{{ "随约平台积分" }}</div>
        <div class="top">
            <Card class="card-item">
                <div class="title-div">
                    <div class="title-content">{{ "积分模式定义" }}</div>
                    <div class="btn-content"><Button type="primary" @click="changeLayer('/mallintegrationmode', '积分模式定义')">定义</Button></div>
                </div>
                <div class="desc-div">模式名称定义，等级名称及数值</div>
                <div class="card-column">
                    <div class="card-div" v-for="(item, index) in modelList" :key="index">
                        <Card class="card-item">
                            <div class="column-title">{{ item.poolName }}</div>
                            <div v-if="item.ruleList && item.ruleList.length">
                                <div class="item-content" v-for="(items, indexs) in item.ruleList" :key="indexs">{{ items.levelName }}:{{ items.minPoints }}</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Card>
            <Card class="card-item">
                <div class="title-div">
                    <div class="title-content">{{ "积分商城" }}</div>
                    <div class="btn-content">
                        <Button type="primary" @click="changeLayer('/mallpointsmall', '商品管理')">商品管理</Button>
                        <Button type="primary" style="margin-left: 10px">订单管理</Button>
                    </div>
                </div>
                <div class="desc-div">模式名称定义，等级名称及数值</div>
                <div class="card-justify">
                    <div class="crad-item">
                        <Card class="card-content">
                            <div class="content-div">
                                <div class="text">福利金</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                            </div>
                        </Card>
                    </div>
                    <div class="crad-item">
                        <Card class="card-content">
                            <div class="content-div">
                                <div class="text">流量池</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                            </div>
                        </Card>
                    </div>
                    <div class="crad-item">
                        <Card class="card-content">
                            <div class="content-div">
                                <div class="text">权益池</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                                <div class="desc">商品: 37个</div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Card>
        </div>
        <div class="bottom">
            <Card class="card-item">
                <div class="title-div">
                    <div class="title-content">{{ "积分规则设置" }}</div>
                    <div class="btn-content">
                        <Button type="primary" @click="changeLayer('/rulepage', '规则说明')">规则说明</Button>
                        <Button type="primary" style="margin-left: 10px" @click="changeLayer('/pointdefinition', '业务点定义')">业务点定义</Button>
                        <Button type="primary" style="margin-left: 10px" @click="changeLayer('/mallintegralrule', '积分规则设置')">积分规则设置</Button>
                    </div>
                </div>
                <div class="desc-div">与业务埋点相结合，为不同模式设置积分计算方式及扣除计算方式</div>
                <div class="card-justify">
                    <div class="crad-item" v-for="(item, index) in taskList" :key="index">
                        <Card class="card-content">
                            <div class="content-div">
                                <div class="text">{{ item.poolName }}</div>
                                <div class="desc"><span>获取:{{item.getNum}}</span> <span style="margin-left:50px">扣除:{{ item.setNum }}</span>   </div>
                            </div>
                        </Card>
                    </div>
                </div>
            </Card>
            <Card class="card-item">
                <div class="title-div">
                    <div class="title-content">{{ "积分排行榜" }}</div>
                    <div class="btn-content">
                        <Button type="primary" @click="changeLayer('/userranking', '积分排行榜')">查看</Button>
                    </div>
                </div>

                <div class="card-column">
                    <Card class="card-item">
                        <div class="column-title">福利金</div>
                        <div class="item-content">硬币：1000</div>
                        <div class="item-content">铜币：1000</div>
                    </Card>
                    <Card class="card-item">
                        <div class="column-title">福利金</div>
                        <div class="item-content">硬币：1000</div>
                        <div class="item-content">铜币：1000</div>
                    </Card>
                    <Card class="card-item">
                        <div class="column-title">福利金</div>
                        <div class="item-content">硬币：1000</div>
                        <div class="item-content">铜币：1000</div>
                    </Card>
                </div>
            </Card>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    destroyed() {},
    data() {
        return {
            modelList: [],
            resultArray: [],

            taskList: [], //积分规则设置
        }
    },
    async created() {
        await this.queryPoolByPage()
        if (this.modelList && this.modelList.length) {
            await this.getAllList()
            this.modelList = this.modelList.map((item, index) => {
                item.ruleList = this.resultArray[index]
                return item
            })
            console.log("this.modelList", this.modelList)
        }

        await this.queryPoolTaskByPage()
    },
    methods: {
        changeLayer(router, name) {
            this.$core.openLayerFrame({
                type: 2,
                title: name,
                content: `/page#${router}`,
                area: ["100%", "100%"],
            })
        },
        // 获取积分模式列表
        async queryPoolByPage() {
            await this.$get("/gateway/sy-points/api/sypointes/pool/queryPoolByPage", {
                page: 1,
                pageSize: 100,
            })
                .then(async res => {
                    if (res.code == 200 && res.dataList) {
                        this.modelList = res.dataList
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取积分模式失败",
                    })
                    return
                })
        },

        getRuleList(poolId) {
            return new Promise(resolve => {
                this.$get("/gateway/sy-points/api/sypointes/pool/listLevelRuleByPoolId", {
                    poolId,
                }).then(res => {
                    if (res.code === "200") {
                        resolve(res.dataList)
                    } else {
                        resolve(null)
                    }
                })
            })
        },
        async getAllList() {
            const primises = this.modelList.map(item => {
                return this.getRuleList(item.poolId)
            })
            const results = await Promise.all(primises)
            this.resultArray = results.filter(result => result !== null)
        },

        // 获取积分商城

        // 积分规则设置
        async queryPoolTaskByPage() {
            this.$get("/gateway/sy-points/api/sypointes/pool/queryPoolTaskByPage", {
                page: 1,
                pageSize: 100,
            })
                .then(res => {
                    if (res.code == 200 && res.dataList) {
                        // this.taskList = res.dataList
                        this.taskList = this.typeForList(res.dataList).map(item => {
                            if (item.data && item.data.length) {
                                item.getNum = 0
                                item.setNum = 0
                                item.data.map(items => {
                                    if (items.taskType == "1") {
                                        item.getNum = item.getNum + 1
                                    } else if (items.taskType == "2") {
                                        item.setNum = item.setNum + 1
                                    }
                                })
                            }
                            return item
                        })
                        console.log(JSON.parse(JSON.stringify(this.taskList)));
                    }
                })
                .catch(err => {
                    this.$Message.error({
                        background: true,
                        content: "获取积分规则设置失败",
                    })
                })
        },

        typeForList(data) {
            return data.reduce((acc, obj) => {
                const found = acc.find(item => item.poolId === obj.poolId)
                if (found) {
                    found.data.push(obj)
                } else {
                    acc.push({ poolId: obj.poolId, data: [obj], poolName: obj.poolName })
                }
                return acc
            }, [])
        },

        // 积分排行榜
    },
}
</script>

<style scoped lang="less">
.homapge {
    padding: 20px;
    .title {
        padding-bottom: 20px;
        font-size: 16px;
        white-space: nowrap;
        cursor: pointer;
        font-weight: bold;
    }
    .top,
    .bottom {
        display: flex;
        justify-content: center;
        .card-item {
            width: 48%;
            margin: 20px;
            .title-div {
                display: flex;
                justify-content: space-between;
                .title-content {
                    font-size: 16px;
                    font-weight: bold;
                }
                .btn-content {
                    margin-left: auto;
                }
            }
            .desc-div {
                color: #333;
                margin-top: 10px;
            }
            .card-column {
                display: flex;
                justify-content: flex-start;
                min-height: 400px;
                overflow-x: auto;
                .card-div {
                    width: 25% !important;
                    margin-right: 20px;
                    flex-grow: 0;
                    flex-shrink: 0;
                }
                .card-item {
                    width: 100%;
                    min-height: 300px;
                    .column-title {
                        color: #30cc8e;
                        text-align: center;
                        margin-bottom: 10px;
                        font-weight: bold;
                    }
                }
            }
            .card-justify {
                max-height: 500px;
                overflow-y: scroll;
                .crad-item {
                    margin: 20px 0;
                    .content-div {
                        margin: 20px 0;
                        display: flex;
                        justify-content: flex-start;
                        .text {
                            font-weight: bold;
                            color: #30cc8e;
                        }
                        .desc {
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
    }
}
</style>